<template >
    <div>
        <topnav></topnav>
        <div class="page-wrap">
            <div class="big-top-img-head who">
                <h1 class="txt160 serif big-top-head loading">
                    {{ $t('About us') }}
                </h1>
                <p class="top-subtitle">
                    {{ $t('Born of a visionary alliance, CLARITY is a fusion of seasoned gemologists, shrewd brokers, and distinguished specialists, boasting two decades of diamond industry acumen.This symphony of expertise births an enterprise that excels for its buyers and sellers.') }}
                </p><img src="../assets/gem-bg.svg" class="big-top-img" />
            </div>

            <!-- <div
                v-if="$lang === 'en'"
                class="tri-colums"
                v-html="whoPage.body"
            >

            </div>
            <div
                v-if="$lang === 'fr'"
                class="tri-colums"
                v-html="whoPage.bodyFrench"
            >
            </div> -->
            <div class="about-info">
                <p>
                    <b>
                        {{ $t('Unveiling a Worldwide Network') }}:
                    </b>
                    {{ $t('2000+ Elite International Buyers Ready to Engage') }}
                </p>
                <p>
                    <b>
                        {{ $t('Crafting Clarity through Seasoned Mastery') }}:
                    </b>
                    {{ $t('Your Trusted Team of Precious Evaluation Connoisseurs!') }}
                </p>
                <p>
                    <b>
                        {{ $t('Boundless Excellence') }}:
                    </b>
                    {{ $t(`Secure, Insured, Seamless Door-to-Door Service from Europe's Heart.`) }}
                </p>
            </div>
            <h3 class="txt88 title-start serif"> {{ $t('Our Locations') }}</h3>
            <BglCarousel class="carousel-locations" :nav="false" :dots="false" :autoWidth="true">

                <div v-for="slide in  $local.offices" :key="slide._id" class="carousel-location-box">
                    <div class="carousel-location-img">
                        <div class=""></div>
                        <img :src="slide.image.imageURL" alt="">
                    </div>
                    <p v-if="$lang === 'en'" class="carousel-location-title">{{ slide.name }}</p>
                    <p v-if="$lang === 'fr'" class="carousel-location-title">{{ slide.nameFrench || slide.name }}</p>

                    <p v-if="$lang === 'en'" v-html="slide.location"></p>
                    <p v-if="$lang === 'fr'" v-html="slide.locationFrench || slide.location"></p>

                </div>

            </BglCarousel>
            <h3 class="txt88 title-start serif"> {{ $t('The Connections') }}</h3>
            <div class="team-wrap" v-if="$lang === 'en'">
                <div class="team-box" v-for="member in connectionsMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.name }}</p>
                        <p class="team-title" v-html="member.title.split('\n').join('<br>')"></p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bio"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="team-wrap" v-if="$lang === 'fr'">
                <div class="team-box" v-for="member in connectionsMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.nameFrench || member.name }}</p>
                        <p class="team-title"
                            v-html="member.titleFrench.split('\n').join('<br>') || member.titleFrench.split('\n').join('<br>')">
                        </p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bioFrench"></div>
                        </div>
                    </div>

                </div>
            </div>
            <h3 class="txt88 title-start serif"> {{ $t('The Expertise') }}</h3>
            <div class="team-wrap" v-if="$lang === 'en'">
                <div class="team-box" v-for="member in expertiseMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.name }}</p>
                        <p class="team-title" v-html="member.title.split('\n').join('<br>')"></p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bio"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="team-wrap" v-if="$lang === 'fr'">
                <div class="team-box" v-for="member in expertiseMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.nameFrench || member.name }}</p>
                        <p class="team-title"
                            v-html="member.titleFrench.split('\n').join('<br>') || member.titleFrench.split('\n').join('<br>')">
                        </p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bioFrench"></div>
                        </div>
                    </div>

                </div>
            </div>
            <h3 class="txt88 title-start serif"> {{ $t('The Service') }}</h3>
            <div class="team-wrap" v-if="$lang === 'en'">
                <div class="team-box" v-for="member in serviceMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.name }}</p>
                        <p class="team-title" v-html="member.title.split('\n').join('<br>')"></p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bio"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="team-wrap" v-if="$lang === 'fr'">
                <div class="team-box" v-for="member in serviceMember" :key="member._id">
                    <div class="team-img">
                        <div class="team-img-color"></div>
                        <img :src="member.image.imageURL" />
                        <div class="linkedIn" v-if="member.linkedIn">
                            <a :href="member.linkedIn" class="team-linkedin" target="_blank">

                                <img src="../assets/linkdin.svg" /></a>
                        </div>

                    </div>
                    <div>
                        <p class="team-name serif">{{ member.nameFrench || member.name }}</p>
                        <p class="team-title"
                            v-html="member.titleFrench.split('\n').join('<br>') || member.titleFrench.split('\n').join('<br>')">
                        </p>
                        <div class="bio-wrap">
                            <div class="team-bio" v-html="member.bioFrench"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <botfooter></botfooter>
    </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import BglCarousel from "@/components/bglCarousel.vue";
import { teamMembers, whoPage, offices } from '@/data/localData.json';

export default {
    components: {
        Topnav,
        Botfooter,
        BglCarousel,

    },
    metaInfo() {
    return {
      ...this.seo({
        title: this.$lang === 'fr'? whoPage.titleFrench : whoPage.title,
        description: this.$lang === 'fr'?whoPage.meta_description_french: whoPage.meta_description
      }),
    };
  },
    computed: {
        $local() {
            return { teamMembers, whoPage, offices }
        },
        connectionsMember() {
            return this.$local.teamMembers.filter((m) => m.type?.value === 'Connections')
        },
        expertiseMember() {
            return this.$local.teamMembers.filter((m) => m.type?.value === 'Expertise')
        },
        serviceMember() {
            return this.$local.teamMembers.filter((m) => m.type?.value === 'Service')
        },
        whoPage() {
            const pageID = "64d365752858cbc6ecf7b4a0"
            return this.$local.whoPage.find(b => b._id === pageID)
        }
    },
    name: "Who we are"
};
</script>

<style scoped>
/* [lang="fr"] .big-top-head {
    max-width: 675px;
} */
</style>