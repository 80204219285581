import { render, staticRenderFns } from "./Whoweare.vue?vue&type=template&id=3a0f8420&scoped=true"
import script from "./Whoweare.vue?vue&type=script&lang=js"
export * from "./Whoweare.vue?vue&type=script&lang=js"
import style0 from "./Whoweare.vue?vue&type=style&index=0&id=3a0f8420&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0f8420",
  null
  
)

export default component.exports